import React from 'react';
//eslint-disable-next-line import/no-unresolved
import logo from '@package/components/layout/images/Logo.png?width=358';

const LogoFooter = () => (
  <figure className="icon">
    <img src={logo} width="358" height="82" alt="CCIAA Padova" />
  </figure>
);

export default LogoFooter;

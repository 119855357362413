/**
 * Add your config changes here.
 * @module config
 */

import applyItaliaConfig from '@italia/config/italiaConfig';
import VirtualAssistant from '@package/components/VirtualAssistant/VirtualAssistant';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['it', 'en'],
    defaultLanguage: 'it',
    showTrasparenzaFields: true,
    matomoSiteId: 'kjpR7jjq1y',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'CCIAA Padova',
      siteSubtitle: '',
      parentSiteTitle: null,
      parentSiteURL: null,
      subsiteParentSiteTitle: 'CCIAA Padova',
      headerslimTertiaryMenu: {
        it: [
          {
            title: 'Prenotazione on line',
            url: 'https://prenotazioni.pd.camcom.it',
          },
          {
            title: 'Contatti',
            url: '/it/la-camera/contatti-PEC',
          },
          {
            title: 'Newsletter',
            url: 'https://camerainforma.camcom.it/pd/newslettersub/',
          },
          {
            title: 'Sala Stampa',
            url: '/it/notizie/sala-stampa',
          },
        ],
        en: [
          {
            title: 'Online booking',
            url: 'https://prenotazioni.pd.camcom.it',
          },
          {
            title: 'Contacts',
            url: '/it/la-camera/contatti-PEC',
          },
          {
            title: 'Newsletter',
            url: 'https://camerainforma.camcom.it/pd/newslettersub/',
          },
          {
            title: 'Press room',
            url: '/it/notizie/sala-stampa',
          },
        ],
      },
      // enableCustomerSatisfaction: false, // false per disabilitare
      //arLoginUrl: '/it/area-riservata',
      //arLogoutUrl: '/logout',
      // smallFooterLinks: {
      //   default: [{ title: 'Mappa del sito', url: '/sitemap' }],
      //   it: [{ title: 'Mappa del sito', url: '/it/sitemap' }],
      //   en: [{ title: 'Sitemap', url: '/en/sitemap' }],
      // },
    },
  };

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2022-12-27T18:00:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
            },
          },
          /*...config.settings[
            'volto-gdpr-privacy'
          ].defaultPanelConfig.technical.choices.filter(
            (f) =>
              f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
          ),*/
        ],
      },
      profiling: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling,
        choices: [
          {
            config_key: 'YOUTUBE',
            referenceUrls: ['youtube.com', 'youtube-nocookie.com', 'youtu.be'],
            text: {
              it: {
                title: 'Youtube',
                description:
                  "I cookie di profilazione di Youtube permettono di mostrarti le pubblicità che potrebbero interessarti di più, fare analisi di accesso alla pagina e sul comportamento dell'utente, facilitare l'accesso ai servizi di Google.",
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'Per vedere il video, accetta i cookies di Youtube.',
              },
              en: {
                title: 'Youtube',
                description:
                  'Youtube profiling cookies allow you to show advertisements that may interest you the most, analyze page access and user behavior, facilitate access to Google services. ',
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'To view the video, please accept Youtube cookies.',
              },
            },
          },
          {
            config_key: 'VIMEO',
            referenceUrls: ['vimeo.com'],
            text: {
              it: {
                title: 'Vimeo',
                description:
                  "I cookie di profilazione di Vimeo permettono di fare analisi di accesso alla pagina e sul comportamento dell'utente, e di mostrarti le pubblicità che potrebbero interessarti di più.",
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'Per vedere il video, accetta i cookies di Vimeo.',
              },
              en: {
                title: 'Vimeo',
                description:
                  'Vimeo profiling cookies allow you to analyze page access and user behavior, and to show you the advertisements that may interest you the most.',
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'To view the video, please accept Vimeo cookies.',
              },
            },
          },
        ],
      },
    },
  };

  // config.views = {
  //   ...config.views,
  // };
  // config.widgets = {
  //   ...config.widgets,
  // };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      // {
      //   id: 'cardWithSideImageTemplate',
      //   isDefault: false,
      //   title: 'Card con immagine affiancata',
      //   template: CardWithSideImageTemplate,
      //   skeleton: CardWithSideImageTemplateSkeleton,
      //   schemaEnhancer: ({ schema, formData, intl }) => {
      //     let pos = addDefaultOptions(schema, formData, intl);
      //     addCardWithSideImageTemplateOptions(schema, formData, intl, pos);
      //     return schema;
      //   },
      // },
    ],
    listing_bg_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
    listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  };

  config.settings.appExtras = [
    ...(config.settings.appExtras || []),
    {
      match: '',
      component: VirtualAssistant,
    },
  ];

  return config;
}

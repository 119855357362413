import React, { useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  chatbot_start_message: {
    id: 'chatbot_start_message',
    defaultMessage: 'Ciao io sono Futura. Clicca qui sotto per parlare con me.',
  },
  chatbot_tooltip: {
    id: 'chatbot_tooltip',
    defaultMessage: 'Ciao sono Futura, come posso aiutarti?',
  },
  chatbot_privacy_message: {
    id: 'chatbot_privacy_message',
    defaultMessage:
      "Proseguendo nella conversazione autorizzo il trattamento dei miei dati personali come specificato nell' <a href='/it/privacy-note-legali/informative-privacy/informativa-privacy-chatbot' target='_self'>Informativa</a> di cui dichiaro aver preso visione",
  },
});

const VirtualAssistant = () => {
  const intl = useIntl();

  useEffect(() => {
    const url = 'https://virtualassistant.alghoncloud.com/algho-viewer.min.js';
    const data = {
      id: 'algho-viewer',
      'bot-id': 'a623f413b33f9068eaaec63cd0957329',
      'auto-hide-message': 0,
      widget: 'true',
      audio: 'true',
      voice: 'true',
      'z-index': '9999',
      'start-message': intl.formatMessage(messages.chatbot_start_message),
      'hide-past-history': 'true',
      'welcome-back-suggestion': 'true',
      tooltip: intl.formatMessage(messages.chatbot_tooltip),
      'privacy-message': intl.formatMessage(messages.chatbot_privacy_message),
    };
    var isMobile =
      'ontouchstart' in document.documentElement &&
      /mobi/i.test(navigator.userAgent);

    //create tag
    const tag = document.createElement('algho-viewer');
    Object.keys(data).map((key) => tag.setAttribute(key, data[key]));
    if (isMobile) {
      tag.setAttribute('open', 'false');
    }

    //create script
    const script = document.createElement('script');
    script.setAttribute('id', 'algho-viewer-module');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('defer', 'defer');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('src', url);

    setTimeout(function () {
      document.body.appendChild(tag);
      document.getElementsByTagName('body')[0].appendChild(script);
    }, 1000);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(tag);
    };
  }, []);

  return <></>;
};

export default VirtualAssistant;
